import React, { useEffect, useState } from "react";
import "./style.scss";

import "react-slideshow-image/dist/styles.css";
import NavBar from "../navbar";

import { Link, Redirect, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import * as api from "../../api/api";
import ErrorTime from "components/ErrorTime";
// import Spinner from "react-bootstrap/Spinner";
import BottomNav from "components/bottomNav";
import { useUuid } from "_context/uuid/uuidProvider";
import { useCategory } from "_context/category/provider";
import { useUtmSource } from "_context/utmSource/uuidProvider";
// import { t } from "i18next";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
// import fall from "../../assets/fall.png";
// import { tr } from "locales";
// // import snow from "../../assets/snow.png";
// import dis from "../../assets/tdis.png";
// import turkey from "../../assets/dis.png";
// import { Card } from "react-bootstrap";
import { pageView } from "../../GTM";
import FullScreenPopup from "components/languagePopup";
import SearchBar from "reuse/SearchBar";
import HomePageSlider from "./Slider/index";
import Skeleton from "react-loading-skeleton";
import ImageFullLoad from "components/ImageFullLoad";
import { useDelivery } from "_context/delivery/deliveryProvider";
import FullScreenOffOrderPopup from "components/OffOrderPopup";
import styles from "./index.module.scss";
import Icons from "reuse/Icons";
import BaklavaSpecialOrderPopup from "components/BaklavaSpecialOrderPopup";
import TourPopup from "components/TourPopup";
import TourCompletePercentage from "components/TourCompletePercentage";
import configs from "../../config";
import SpecialOfferPopup from "components/SpecialOfferPopup";
import { popupNames } from "enumeration";
import { useCarts } from "_context/shoppingCart/cartProvider";
import ChristmasPopup from "components/ChristmasPopup";

const NewCategory = () => {
	// const fadeImages = [
	//   {
	//     url: "https://d35ns7pomhypr0.cloudfront.net/96fc1bb5-cfef-46b5-a057-6927c2618fae-w.png",
	//   },
	//   {
	//     url: "https://d35ns7pomhypr0.cloudfront.net/0d659f18-6634-4fdd-b3b2-12f67144e34d-fav.png",
	//   },
	// ];

	const { data: uuid } = useUuid();
	const { data: dData } = useDelivery();
	const [tourPopup, setTourPopup] = useState(false);
	const [specialOfferPopup, setSpecialOfferPopup] = useState(false);
	const [christmasPopup, setChristmasPopup] = useState(false);

	const { addCount, cart, removeItem, addCart } = useCarts();

	const [BaklavaVisibleSpecialOrder, handleCloseBaklavaVisibleSpecialOrder] = useState();
	const { initial: UtmInitial, data: UtmData, setData: utmSetData, setUserId, setVisibledPopup, setUnVisiblePopup } = useUtmSource();

	const [visible, setVisible] = useState(false);
	const [visibleOffOrder, setVisibleOffOrder] = useState(false);

	const queryHotel = useQuery("hotel", () => api.getHotel(uuid), {
		enabled: !!uuid,
		onSuccess: (data) => {
			console.log("* * * hotel : ", { data });
			const visibledSpecialPop = UtmData?.visibledPopup?.[popupNames?.specialOffer];

			// const validChrisPop = UtmData?.visibledPopup?.[popupNames?.christmas];
			// if (!validChrisPop) {
			// 	const img = new Image();
			// 	img.src = require("../../assets/christmas.png");
			// 	img.onload = () => {
			// 		setTimeout(() => {
			// 			setChristmasPopup(true);
			// 		}, 500);
			// 	};
			// }

			if (data?.campaignProduct?.id) {
				// const validPop = UtmData?.visibledPopup?.[popupNames?.specialOrders];
				// if (!validPop) {
				// 	setTimeout(() => {
				// 		handleCloseBaklavaVisibleSpecialOrder(true);
				// 	}, 1000);
				// }
				const showSpecialOffer = ["652ac112ba9dedcf3aec1e84"].includes(data?.region?._id);
				setTimeout(() => {
					if (showSpecialOffer) {
						if (!visibledSpecialPop) {
							// setSpecialOfferPopup(true);
							setVisibledPopup(popupNames?.specialOffer);
						}
					}
				}, 2000);
			} else {
				if (visibledSpecialPop) {
					setUnVisiblePopup(popupNames?.specialOffer);
					const find = cart?.shoppingCart?.find((x) => x?.data?.isCampaignOffer);
					console.log("* * * hotel : ", { find, cart });
					if (find) removeItem(find?.data);
				}
			}

			const showTour = configs.supportedRegionTour.includes(data?.region?._id);
			setTimeout(() => {
				if (showTour) {
					const visibledTourPop = UtmData?.visibledPopup?.[popupNames?.tour];
					if (!visibledTourPop) {
						setTourPopup(true);
					}
				}
			}, 2000);
		},
	});
	// useEffect(() => {
	// 	UtmInitial();
	// }, []);

	const showTour = configs.supportedRegionTour.includes(queryHotel?.data?.region?._id);
	console.log({ queryHotel, showTour });
	// --------------------------------

	const handleCloseTourPopup = () => {
		setVisibledPopup(popupNames?.tour);
		setTourPopup(false);
	};

	const handleCloseSpecialOffer = () => {
		setVisibledPopup(popupNames?.specialOffer);
		setSpecialOfferPopup(false);
	};

	const handleCloseChristmas = () => {
		setVisibledPopup(popupNames?.christmas);
		setChristmasPopup(false);
	};
	const handleCloseSpecialOrder = () => {
		setVisibledPopup(popupNames?.specialOrders);
		handleCloseBaklavaVisibleSpecialOrder(false);
	};
	// --------------------------------

	console.log({ UtmData });
	const handleSuccessCategory = (data) => {
		// console.log("* * * handleSuccessCategory : ", { data });
		const statisticsUserId = data.data.statisticsUserId;
		if (statisticsUserId) setUserId(statisticsUserId);
	};

	let {
		data: { data } = {},
		isError,
		error,
		isLoading,
		refetch,
		isFetching,
	} = useQuery(
		"menuCategories",
		() =>
			api.getMenuCategories(
				uuid,
				UtmData?.isMagnet ? "magnet" : UtmData?.isCatalog ? "catalog" : undefined,
				UtmData.language && !UtmData?.userId,
				dData?.staticRoomNumber,
				!UtmData?.userId && UtmData.language
			),
		{
			enabled: !!uuid && !!UtmData.language,
			// staleTime: 100000,
			retry: false,
			onSuccess: handleSuccessCategory,
		}
	);

	useEffect(() => {
		if (UtmData?.userId || !UtmData?.language) return;
		refetch();
		// queryClient.invalidateQueries("menuCategories");
	}, [UtmData?.language]);

	const banner = data?.categories?.banner;
	// const { data: banner } = useQuery("banner", () => api.getBanner(uuid));
	// const { data: banner } = useQuery("banner", () => api.getBanner(uuid), {
	// 	enabled: !!uuid,
	// 	retry: false,
	// });
	// console.log("Banner ===>", banner);

	const { data: catData, setParent, setChild, initial } = useCategory();

	const handleCloseLanguage = () => {
		localStorage.setItem("pop_status", 1);
		setVisible(false);
	};

	const handleCloseOrder = () => {
		localStorage.setItem("pop_order", 1);
		setVisibleOffOrder(false);
	};

	// console.log({ data, error, catData, UtmData });
	const { t, i18n } = useTranslation();

	// useEffect(() => {
	//   if (!UtmData?.userId && !isFetching) refetch()
	// }, [UtmData?.userId]);

	useEffect(() => {
		pageView(window?.location?.pathname);
		// console.log("pathName", window.location.pathname);
	}, []);

	useEffect(() => {
		let pop_status = localStorage.getItem("pop_status");
		let pop_order = localStorage.getItem("pop_order");

		if (!pop_status) {
			setVisible(true);
			// localStorage.setItem("pop_status", 1);
		}

		if (pop_status && !pop_order) {
			setVisibleOffOrder(true);
			// localStorage.setItem("pop_order", 1);
		}
	}, [visible]);

	// if (isLoading) {
	//   return (
	//     <div className="text-center">
	//       {" "}
	//       <Spinner animation="border" />
	//     </div>
	//   );
	// }
	if (isError) {
		return <ErrorTime message={error?.response?.data?.message} />;
	}
	// console.log("visible", visible, { array: new Array(15).fill() });
	// isLoading = true
	return (
		<div className="">
			{/* <FullScreenPopup
        isOpen={visible}
        // onRequestClose={() => setVisible(false)}
        onRequestClose={handleCloseLanguage}
      /> */}
			{/* <SpecialOfferPopup isOpen={specialOfferPopup} onRequestClose={handleCloseSpecialOffer} /> */}
			{/* <ChristmasPopup isOpen={christmasPopup} onRequestClose={handleCloseChristmas} /> */}
			<TourPopup isOpen={tourPopup} onRequestClose={handleCloseTourPopup} />
			<BaklavaSpecialOrderPopup isOpen={BaklavaVisibleSpecialOrder} onRequestClose={handleCloseSpecialOrder} />
			<FullScreenOffOrderPopup isOpen={visibleOffOrder} onRequestClose={handleCloseOrder} />
			{/* <div className="inputWrapper">
        <i className="fa-solid fa-search inputIcon" />
        <input className="searchInput" placeholder="Search..."></input>
      </div> */}
			<NavBar />
			<div className="px-2">{isLoading ? <Skeleton height={30} className="mb-2" /> : <SearchBar />}</div>
			<div className="slide-container mt-11 px-2">
				<HomePageSlider data={banner} {...{ setChild, setParent }} />
			</div>
			<div className="px-3 mt-1">
				<div className="row cardContainer ">
					{(data?.categories?.other || new Array(15).fill())?.map((parameter) => {
						const isTranslate = parameter?.translations?.reduce((obj, x) => {
							obj[x.language] = x;
							return obj;
						}, {});
						const textTranslate = isTranslate?.[i18n?.language];

						return (
							<Link
								key={parameter?.id}
								to={{
									pathname: parameter?.children > 0 ? `/child/${parameter?.id}` : `/product/${parameter?.id}`,
								}}
								className="col-md-3 col-sm-3 col-3"
								onClick={(e) => {
									if (!parameter?.id) e.preventDefault();
									if (!parameter?.children) {
										setChild(false);
									}
									setParent(parameter);
									// console.log("parameterss", parameter);
								}}>
								<div className={["catCard", styles.cardBox].join(" ")}>
									{/* <div className={styles.heart}>
                    <Icons.HeartFill />
                  </div> */}
									{/* <img src={parameter.image} className="catImage" alt="..." /> */}
									<ImageFullLoad src={parameter?.image} className="catImage" />
									<span className="catName"> {isLoading ? <Skeleton className="mt-1" /> : textTranslate?.name || parameter?.name}</span>
								</div>
							</Link>
						);
					})}
				</div>
			</div>

			{<div className="empty-space-cat" />}

			<BottomNav />
		</div>
	);
};
export default NewCategory;

{
	/*
	 */
}
