import { getDolarRate, getEuroRate, getHotel, postOrders } from "api/api";
import { PAYMENT_DATA, PAYMENT_DATA_OBJECT, popupNames, SYMBOLS_DATA_OBJECT, UUID_KY } from "enumeration";
import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { changePriceFormat, numberWithCommas } from "utils/calculators";
import { useDelivery } from "_context/delivery/deliveryProvider";
import { usePayment } from "_context/payment/paymentProvider";
import { useCarts } from "_context/shoppingCart/cartProvider";
import { useUuid } from "_context/uuid/uuidProvider";
import swal from "sweetalert";
import { useCategory } from "_context/category/provider";
import { useUtmSource } from "_context/utmSource/uuidProvider";
import { pageView } from "../../GTM";
import "./style.scss";
import { Input } from "react-bootstrap";
import PageNavbar from "components/PageNavbar/pageNavbar";
import Decimal from "decimal.js";
import { useDiscount } from "_context/discount/discountProvider";
import styles from "./index.module.scss";
import ModalLayout from "components/ModalLayout";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";
import compareTranslate from "utils/compareTranslate";
import ImageFullLoad from "components/ImageFullLoad";
import MarqueeComponent from "../MarqueeComponent";

export const addPricePercent = (total) => {
	return total + calculateValueWithPercent(total, 12.5);
};
export const calculateValueWithPercent = (totalValue, partialValue) => {
	// return (totalValue / 100) * partialValue;
	return new Decimal(totalValue || 0).div(100).mul(partialValue);
};
// if (hotel.serviceCharge) totalPrice = addPricePercent(totalPrice);

export default function Payment() {
	const { t, i18n } = useTranslation();
	let [searchParams] = useSearchParams();
	let location = useLocation();

	const errorSearchParams = searchParams.get("error");

	const [isOpen, setIsOpen] = useState(false);
	const [isToggleCartModal, setIsToggleCartModal] = useState({
		toggle: false,
		result: null,
	});
	const { data: uuid } = useUuid();

	const discountState = useDiscount();
	const navigate = useNavigate();

	const { initial: UtmInitial, data: UtmData, setData: utmSetData, setUserId, initialVisibledPopup, setUnVisiblePopup } = useUtmSource();

	const { data: delivery, onChange, initial: dInitial } = useDelivery();
	const { data: payment, onSelect, onSelectCurrency, onChangeNote, initial: pInitial } = usePayment();
	const { initial: catInitial } = useCategory();
	const { cart, initial: cInitial, removeItem } = useCarts();

	const validateEmail = (email) => {
		return (email || "")?.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};
	let isPromotion = cart?.shoppingCart?.some((x) => x.data?.promotionCodes);

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
		watch,
	} = useForm({
		resolver: yupResolver(
			validation({
				email: isPromotion ? true : false,
			})
		),
		mode: "onBlur",
		defaultValues: {
			...delivery,
		},
	});

	const nameValid = delivery?.name?.trim();
	let emailValid = delivery?.email?.trim() ? !!delivery?.email : isPromotion ? false : true;
	const roomValid = delivery?.roomNumber?.trim()?.length > 0;
	console.log({ location, errorSearchParams, errors, delivery });

	const isNetworkDry = useMemo(() => {
		return !![...(cart?.shoppingCart || [])]?.find((x) => x?.data?.isNetworkDry);
	}, [cart]);
	let checkNetworkDry = !UtmData.networkDry && isNetworkDry;
	// if(emailValid)
	useEffect(() => {
		if (checkNetworkDry) navigate("/cart");
	}, [checkNetworkDry]);

	useEffect(() => {
		if (!cart?.shoppingCart?.length) navigate("/");
	}, [cart]);

	console.log({ cart, isPromotion });

	const {
		data: hotel,
		isError,
		error,
	} = useQuery("hotel", () => getHotel(uuid), {
		enabled: !!uuid,
		onSuccess: (data) => {
			console.log("* * * hotel : ", { data });
			const visibledSpecialPop = UtmData?.visibledPopup?.[popupNames?.specialOffer];
			if (data?.campaignProduct?.id) {
				// const find = cart?.shoppingCart?.find((x) => x?.data?.isCampaignOffer);
				// if (find) {
				// 	const check = find?.data?.id !== data?.campaignProduct?.id;
				// 	console.log({ check });
				// }
			} else {
				if (visibledSpecialPop) {
					setUnVisiblePopup(popupNames?.specialOffer);
					const find = cart?.shoppingCart?.find((x) => x?.data?.isCampaignOffer);
					console.log("* * * hotel : ", { find, cart });
					if (find) removeItem(find?.data);
				}
			}
		},
	});
	const { data: currency } = useQuery("currency", () => getDolarRate());
	// if (hotel?.payment) hotel.payment.isCashActive = false;

	console.log("currency =====>", currency);

	const currencyMultiplier = hotel?.region?.currencyMultiplier || 1.08;

	const menuCurrency = hotel?.region?.menuCurrency;
	const menuCurrencyIsTry = menuCurrency == "try";
	let symbol = SYMBOLS_DATA_OBJECT?.[menuCurrency]?.label;

	useEffect(() => {
		if (menuCurrencyIsTry && menuCurrencyIsTry) onSelectCurrency("currency", "try");
		else onSelectCurrency("currency", menuCurrency);
	}, [menuCurrencyIsTry]);

	const fee = 12.5;
	const serviceFee = hotel?.serviceCharge ? calculateValueWithPercent(cart?.prices, fee) : 0;

	console.log("total ==> ==>", { serviceFee, currencyMultiplier });

	const initialAll = () => {
		dInitial();
		pInitial();
		cInitial();
		catInitial();
		discountState.initial();
		setUserId();
		initialVisibledPopup();
		navigate("/done");
	};

	const onSuccessMutating = (response) => {
		console.log("response = ", response);
		if (response?.data?.paymentType === "online") {
			console.log("online ");
			// navigate(response.data.paymentUrl);
			// window.location.href = response.data.paymentUrl;
			if (response?.data?.paymentUrl) window.location.href = response?.data?.paymentUrl;
			else {
				initialAll();
			}
		} else initialAll();
	};

	const {
		data: orders,
		mutate,
		isLoading,
	} = useMutation("orders", (params) => postOrders(uuid, params, UtmData?.isCatalog, UtmData?.isMagnet), {
		onSuccess: onSuccessMutating,
		onError: (error) => {
			console.log({ error });
			if (error?.response?.status === 406) {
				setIsToggleCartModal((p) => ({
					...p,
					toggle: true,
					result: error?.response?.data,
				}));
			} else swal(t("error"), t(error?.response?.data?.message), "error");
		},
	});

	console.log({ hotel });
	console.log({ UtmData });

	// const accept = payment?.paymentType;
	let acceptGuest = nameValid && roomValid && emailValid;

	if (isPromotion) acceptGuest = acceptGuest;
	let currentCurrency = SYMBOLS_DATA_OBJECT[menuCurrency];

	let paymentValid = useMemo(() => {
		if (!hotel) return false;
		let valid = Object.values(hotel?.payment || {})?.filter((x) => x);
		if (valid?.length && payment?.paymentType) return true;
		return false;
	}, [payment, hotel]);

	acceptGuest = acceptGuest && paymentValid;

	console.log("isOpen", isOpen);
	useEffect(() => {
		pageView(window.location.pathname);
	}, []);
	let updatedPaymentData = [...PAYMENT_DATA];
	let updatedPaymentDataObject = { ...PAYMENT_DATA_OBJECT };

	if (hotel?.id && hotel?.id?.toLocaleLowerCase?.() === "67696464bdea89f07dd02f3b".toLocaleLowerCase()) {
		updatedPaymentData = [PAYMENT_DATA_OBJECT.online];

		updatedPaymentDataObject = {
			[PAYMENT_DATA_OBJECT.online.value]: PAYMENT_DATA_OBJECT.online,
		};
	} else if (hotel?.id && hotel?.id?.toLocaleLowerCase?.() === "66be6f7c535f2615a86c0231".toLocaleLowerCase()) {
		updatedPaymentData = [PAYMENT_DATA_OBJECT.online, PAYMENT_DATA_OBJECT.cash];

		updatedPaymentDataObject = {
			[PAYMENT_DATA_OBJECT.online.value]: PAYMENT_DATA_OBJECT.online,
			[PAYMENT_DATA_OBJECT.cash.value]: PAYMENT_DATA_OBJECT.cash,
		};
	}

	// console.log({ updatedPaymentData, updatedPaymentDataObject });

	useEffect(() => {
		if (!hotel) return;
		// console.log("* * * useEffect payment?.paymentType : ", payment?.paymentType);
		// console.log("* * * useEffect PAYMENT_DATA_OBJECT?.[payment?.paymentType]?.value : ", PAYMENT_DATA_OBJECT?.[payment?.paymentType]?.value);
		// console.log("* * * useEffect PAYMENT_DATA_OBJECT?.[payment?.paymentType]?.key : ", PAYMENT_DATA_OBJECT?.[payment?.paymentType]?.key);
		// console.log("* * * useEffect hotel?.payment[PAYMENT_DATA_OBJECT?.[payment?.paymentType]?.key] : ", hotel?.payment[PAYMENT_DATA_OBJECT?.[payment?.paymentType]?.key]);

		if (!payment?.paymentType && hotel?.payment?.[updatedPaymentDataObject?.online?.key]) {
			onSelect("paymentType", updatedPaymentDataObject?.online?.value);
		}

		if (!payment?.paymentType) return;
		console.log({ payment, hotelPayment: hotel.payment, updatedPaymentDataObject }, updatedPaymentDataObject?.[payment?.paymentType]?.value);

		if (
			!updatedPaymentDataObject?.[payment?.paymentType]?.value ||
			(updatedPaymentDataObject?.[payment?.paymentType]?.value && !hotel?.payment?.[updatedPaymentDataObject?.[payment?.paymentType]?.key])
		)
			onSelect("paymentType", false);
	}, [hotel]);

	// console.log("paymentType", payment?.paymentType);
	// console.log("paymentValid", paymentValid, acceptGuest);

	let orderLimit = hotel?.orderLimit;

	if (currentCurrency?.isTry) orderLimit = new Decimal(orderLimit || 0).mul(new Decimal(currency?.euro || 0).mul(currencyMultiplier));
	// if (currentCurrency?.isEur) orderLimit = new Decimal(orderLimit || 0).div((currency?.euro || 0) * 1.08);
	// else if (currentCurrency?.isDollar) orderLimit = new Decimal(orderLimit || 0).div(currency?.dollar);
	orderLimit = orderLimit && +orderLimit;

	let totalWithService = cart.totalWithService;
	let total = new Decimal(cart.withDiscountPrice || cart.totalWithService || 0);
	// let total = new Decimal(cart?.prices || 0);
	// let totalWithService = total;
	// if (serviceFee) {
	// 	total = total.add(serviceFee);
	// 	totalWithService = total;
	// }

	const orderLimitValid = orderLimit ? orderLimit <= +totalWithService : true;

	const disable = !orderLimitValid;

	// if (discountState?.data?.amount) total = total.sub(total.div(100).mul(discountState?.data?.amount || 0));
	// total = +total;

	console.log({
		totalWithService: +totalWithService,
		total: +total,
		orderLimit,
		currency,
		disable,
		symbol,
		cart,
		hotel,
		SYMBOLS_DATA_OBJECT,
	});

	useEffect(() => {
		if (disable) navigate("/cart");
	}, [disable]);

	// let isDiscount = discountState?.data?.amount != false && discountState?.data?.amount >= 0;
	// console.log("PAYMENT_DATA_OBJECT?.online?.value", hotel?.payment[PAYMENT_DATA_OBJECT?.online?.key], !payment?.paymentType && hotel?.payment[PAYMENT_DATA_OBJECT?.online?.key]);
	// console.log(
	//   "NAN ==>",
	//   changePriceFormat((total) * currency?.euro)
	// );

	const onSubmit = () => {
		if (isLoading) return;
		const values = getValues();
		console.log("* * * onSubmit : ", { values });
		// if (disable) return;
		// return alert();
		mutate({
			name: values?.name,
			...(values?.email?.trim?.() && { email: values.email }),
			roomNumber: values?.roomNumber,
			products: cart?.shoppingCart?.map((x) => ({
				product: x?.data?.id,
				quantity: x?.count,
				meloAppointmentDate: x?.meloAppointmentDate,
			})),
			paymentType: payment?.paymentType,
			note: values?.note,
			paymentCurrency: payment?.currency,
			statisticsUserId: UtmData?.userId,
			code: discountState?.data?.code || undefined,
		});
	};

	const handleChangeUrl = () => {
		navigate("/payment");
	};

	let modalParams = {
		title: t("bankErrorTitle"),
		show: errorSearchParams,
		setShow: handleChangeUrl,
	};

	let cache = useMemo(() => {
		return {
			try: {
				eur: +new Decimal(total || 0).div(currency?.euro || 0).mul(currencyMultiplier),
				try: +(total || 0),
				dollar: +new Decimal(total || 0).div(currency?.dollar || 0),
			},
			eur: {
				eur: +(total || 0),
				try: +new Decimal(total || 0).mul(currency?.euro || 0).mul(currencyMultiplier),
				dollar: +new Decimal(total || 0).mul(currency?.euro || 0).div(currency?.dollar || 0),
			},
		};
	}, [currency, total]);

	const modalCartErrorParams = {
		title: t("Order Failed"),
		show: isToggleCartModal?.toggle,
		setShow: setIsToggleCartModal,
	};

	console.log("* * * payment : ", { total, cache: +(cache[menuCurrency]?.try || 0), currency, currencyMultiplier });

	return (
		<div>
			<ModalLayout {...modalCartErrorParams}>
				<Alert variant={"danger"}>{t("The products you selected are not available right now, try editing and completing the cart.")}</Alert>
				<div>
					<h5>{"Products"}</h5>

					<ul className="p-0">
						{isToggleCartModal?.result?.undefinedProducts?.map((x) => {
							const isTranslate = {};
							x?.translations?.forEach((y) => (isTranslate[y?.language] = y));
							const textTranslate = isTranslate[i18n?.language];

							return (
								<li className="d-flex align-items-center gap-2" key={`undefined-product-${x.id}`}>
									<ImageFullLoad parentProps={{ style: { width: "100px", height: "100px" } }} src={x.images} />
									<div className="d-flex justify-content-between w-100">
										<div className="d-flex flex-column">
											<span> {(textTranslate || x)?.name}</span>
											<span>{x.shortDesc}</span>
										</div>
										<div className="d-flex flex-column">
											<span>
												{numberWithCommas(changePriceFormat(x.price))} {currentCurrency?.label}
											</span>
										</div>
									</div>
								</li>
							);
						})}
					</ul>
				</div>
				<Link to={"/cart"}>
					<Button className={"ml-auto"} variant="outline-success">
						{t("backCart")}
					</Button>
				</Link>
			</ModalLayout>
			<ModalLayout {...modalParams}>
				<Alert variant={"danger"}>{t("bankErrorDescription")}</Alert>
				<Button onClick={handleChangeUrl}>ok</Button>
			</ModalLayout>
			<PageNavbar text={t("delivery") + " & " + t("payment")} path="/cart" />
			<div className="paymentContainer">
				<div className="deliveryBar">
					<div className="location-delivery">
						<i className="fa-solid fa-location-dot locationIcon mr-2" />
						<h4 className="hotelName"> {<MarqueeComponent text={hotel?.name} />}</h4>
					</div>
					<div className="greenbar ">
						<h4 className="time">{`${hotel?.averageDeliveryTime || "21-31"} Min`}</h4>
					</div>
				</div>
				<form onSubmit={handleSubmit(onSubmit)} noValidate className="d-flex flex-column w-100">
					<div className="inputs w-100">
						<Controller
							control={control}
							ref={register}
							name={fieldNames.name}
							render={({ field: { onChange: change, ...field }, fieldState: { error } }) => {
								const handleChange = (e) => {
									change(e);
									onChange(e);
								};

								console.log({ error });
								return (
									<div className="d-flex flex-column w-100">
										<input
											type="text"
											className={`nameInput ${error ? "border-danger" : "border-success"}`}
											placeholder={"*" + t("yourName")}
											id="adi_soyadi"
											// name="name"
											onChange={handleChange}
											// value={delivery?.name}
											isSearchable={false} //
											{...field}
										/>
										{error ? <small className="text-danger mt-1">{compareTranslate(t, error?.message)}</small> : ""}
									</div>
								);
							}}
						/>
						<Controller
							control={control}
							ref={register}
							name={fieldNames.email}
							render={({ field: { onChange: change, ...field }, fieldState: { error } }) => {
								const handleChange = (e) => {
									change(e);
									onChange(e);
								};

								console.log({ error });
								return (
									<div className="d-flex flex-column w-100">
										<input
											type="text"
											className={`nameInput ${error ? "border-danger" : "border-success"}`}
											placeholder={t("Email")}
											id="adi_soyadi"
											// name="name"
											onChange={handleChange}
											// value={delivery?.name}
											isSearchable={false} //
											{...field}
										/>
										{error ? <small className="text-danger mt-1">{compareTranslate(t, error?.message)}</small> : ""}
									</div>
								);
							}}
						/>

						{/* {
							// isPromotion ?
							<input
								type="text"
								className={`nameInput ${!emailValid ? "border-danger" : "border-success"}`}
								placeholder={t("email")}
								id="adi_soyadi"
								name="email"
								onChange={onChange}
								value={delivery?.email}
								isSearchable={false}
							/>
							// : ""
						} */}
						{/* {!emailValid ? <small className="text-danger">{t("please check email address")}</small> : ""} */}

						<Controller
							control={control}
							ref={register}
							name={fieldNames.roomNumber}
							render={({ field: { onChange: change, ...field }, fieldState: { error } }) => {
								const handleChange = (e) => {
									change(e);
									onChange(e);
								};

								console.log({ error });
								return (
									<div className="d-flex flex-column w-100">
										<input
											type="text"
											className={`nameInput ${error ? "border-danger" : "border-success"}`}
											placeholder={"*" + t("roomNumber")}
											id="adi_soyadi"
											// name="name"
											onChange={handleChange}
											// value={delivery?.name}
											isSearchable={false} //
											{...field}
										/>
										{error ? <small className="text-danger mt-1">{compareTranslate(t, error?.message)}</small> : ""}
									</div>
								);
							}}
						/>
						{/* <input
							type="text"
							id="oda_no"
							name="roomNumber"
							className={`nameInput  ${!roomValid ? "border-danger" : "border-success"}`}
							placeholder={"*" + t("roomNumber")}
							onChange={onChange}
							value={delivery?.roomNumber}
							isSearchable={false} //
						></input> */}

						<Controller
							control={control}
							ref={register}
							name={fieldNames.note}
							render={({ field: { onChange: change, ...field }, fieldState: { error } }) => {
								const handleChange = (e) => {
									change(e);
									onChange(e);
								};

								console.log({ error });
								return (
									<div className="d-flex flex-column w-100">
										<textarea
											style={{ borderRadius: "10px" }}
											type="text"
											className={`nameInput ${error ? "border-danger" : "border-success"}`}
											placeholder={t("note")}
											id="adi_soyadi"
											// name="name"
											onChange={handleChange}
											// value={delivery?.name}
											isSearchable={false} //
											{...field}
										/>
										{error ? <small className="text-danger mt-1">{compareTranslate(t, error?.message)}</small> : ""}
									</div>
								);
							}}
						/>
						{/* <textarea
							style={{ borderRadius: "10px" }}
							placeholder={t("orderNote")}
							aria-label="With textarea"
							className="form-control nameInput border-success"
							id="siparisNot"
							name={"note"}
							onChange={onChangeNote}
							value={payment?.note}
							isSearchable={false} //
						/> */}
					</div>
					<div
						className="grid gap-3 maxWidth mx-2"
						style={{
							display: "flex",
							// alignItems: "center",
							justifyContent: "center",
							margin: "auto",
						}}>
						{updatedPaymentData?.map((x) => {
							const active = hotel?.payment?.[x?.key];
							if (!active) return "";
							if (x.value === "online") {
								// check if payment type is 'online'
								return (
									<button
										className={`btnPayment text-black ${x?.value === payment?.paymentType ? " successBack" : ""} ${!active ? "disabled" : ""}`}
										type="button"
										key={x.value}
										onClick={() => onSelect("paymentType", x.value)}>
										<i className={`paymentIcon ${x.icon}`} />
										{t(x.label)}
										<i class={`${x?.value === payment?.paymentType ? "fa-solid" : "fa-regular"} fa-circle`}></i>{" "}
									</button>
								);
							} else {
								// render a regular button for other payment types
								return (
									<button
										className={`btnPayment text-black ${x?.value === payment?.paymentType ? " successBack " : ""} ${!active ? "disabled" : ""}`}
										type="button"
										key={x.value}
										onClick={() => onSelect("paymentType", x.value)}>
										<i className={`paymentIcon ${x.icon}`} />
										{"  "} {t(x.label)}
										<i class={`${x?.value === payment?.paymentType ? "fa-solid" : "fa-regular"} fa-circle`}></i>
									</button>
								);
							}
						})}
					</div>
					{payment?.paymentType === "cash" ? ( //&& !menuCurrencyIsTry
						<>
							<h6 className="money">{t("cashCurrency")}</h6>
							<h6 className="moneyInfo">{t("exchangeInfo")}</h6>
						</>
					) : (
						""
					)}
					<div
						className="grid gap-3 mt-3 mx-2"
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							margin: "auto",
						}}>
						{payment?.paymentType === "cash" ? ( //&& !menuCurrencyIsTry
							"active" ? (
								<>
									<button
										className={`maxWidthCurrency currency paymentPadding text-black btnPayment${"eur" === payment?.currency ? " successBack" : ""} ${
											"active" ? "disabled" : ""
										}`}
										type="button"
										key={"cash"}
										onClick={() => onSelectCurrency("currency", "eur")}>
										<span className="euroSign">€</span>
										<span className="cashTotal">{numberWithCommas(changePriceFormat(cache[menuCurrency]?.eur || 0))}</span>

										<i class={`${payment?.currency === "eur" ? "fa-solid" : "fa-regular"} fa-circle`}></i>
									</button>

									<button
										className={`maxWidthCurrency currency paymentPadding text-black btnPayment${"try" === payment?.currency ? " successBack" : ""} ${
											"active" ? "disabled" : ""
										}`}
										type="button"
										key={"cash"}
										onClick={() => onSelectCurrency("currency", "try")}>
										<span className="euroSign">₺</span>
										<span className="cashTotal">
											{
												// (total) * currency?.euro * 1.08
												numberWithCommas(changePriceFormat(cache[menuCurrency]?.try || 0))
											}{" "}
										</span>
										{/* <br /> */}
										<i class={`${payment?.currency === "try" ? "fa-solid" : "fa-regular"} fa-circle`}></i>
									</button>
									<button
										className={`maxWidthCurrency currency text-black paymentPadding btnPayment${"usd" === payment?.currency ? " successBack" : ""} ${
											"active" ? "disabled" : ""
										}`}
										type="button"
										key={"cash"}
										onClick={() => onSelectCurrency("currency", "usd")}>
										{/* <i className={`paymentIcon "cash"`} /> */}
										<span className="euroSign">$</span>
										{/* <br /> */}
										<span className="cashTotal">
											{numberWithCommas(
												changePriceFormat(
													// ((total) * currency?.euro) / currency?.dollar
													cache[menuCurrency]?.dollar
												)
											)}
										</span>
										<i class={`${payment.currency === "usd" ? "fa-solid" : "fa-regular"} fa-circle`}></i>
									</button>
								</>
							) : null
						) : null}
					</div>
					{/* hotel?.payment?.[x?.key] */}
					{hotel && !hotel?.payment?.isCashActive ? <div className={styles["payment-text"]}>{t("paymentText")}</div> : ""}
					{/* {payment?.paymentType != "cash" ? ( */}

					<div className="bottom-link my-2">
						<a className="bottom-link-text" href="/privacy" target="__blank">
							KVKK / GDPR
						</a>
					</div>

					<div className="totalContainer" disabled={disable}>
						<span className="totalTitle bold">
							Total {" ₺"}
							{/* {symbol} */}
						</span>
						<span className="totalPrice">
							{" "}
							{numberWithCommas(
								changePriceFormat(
									menuCurrencyIsTry
										? total
										: // (total) *
										  // currency?.euro *
										  // 1.08
										  +(cache[menuCurrency]?.try || 0)
								)
							)}
							{" ₺"}
							{/* {symbol} */}
						</span>
					</div>

					{/* ) : (
          ""
        )} */}
					{/* {!acceptGuest ? (
						<div className="completeContainerNotValid mt-4">{t("notValid")}</div>
					) : isLoading ? (
						<div className="completeContainerNotValid mt-4">...</div>
					) : ( */}
					<button
						className={`completeContainer `}
						type={"submit"}
						disabled={isLoading}
						// onClick={!disable ? onSubmit : null}
					>
						{/* ${disable ? "completeContainerNotValid" : ""} */}
						{/* {disable ? t("minimumCart", { author: orderLimit + symbol }) : t("completeOrder")} */}
						{/* {t("completeOrder")} */}
						{isLoading ? <Spinner animation="border" /> : disable ? t("minimumCart", { author: orderLimit + symbol }) : t("completeOrder")}
					</button>
					{/* )}{" "} */}
				</form>
			</div>
		</div>
	);
}
